import { map, head, keys, values, isObject } from "lodash";

import CustomConfigurator from "CustomConfigurator";

// Custom composer config for sani
CustomConfigurator.set("markdownEditor.colors", [
  { style: "#009ee0", label: "Sani Cyan" },
  { style: "#000000", label: "Sani Schwarz" },
  { style: "#004a99", label: "Sani Dunkelblau" },
  { style: "#16afa5", label: "Sani Türkis" },
  { style: "#88ba14", label: "Sani Grün" },
  { style: "#036430", label: "Sani Dunkelgrün" },
  { style: "#cbbb9d", label: "Sani Warmgrau" },
  { style: "#f9b211", label: "Sani Gelb" },
  { style: "#ee7f00", label: "Sani Orange" },
  { style: "#c50542", label: "Sani Bordeauxrot" },
  { style: "#e2007a", label: "Sani Magenta" },
  { style: "#93569a", label: "Sani Lila" },
  { style: "#7588be", label: "Sani Dunkelblau (60)" },
]);
